<template>
    <div>
        <nav class="tab_box">
        <router-link class="tab_content" to="/company">Company</router-link>
        <router-link class="tab_content" to="/service">Service</router-link>
        <router-link class="tab_content" to="/recruit">Recruit</router-link>
        <router-link class="tab_content" to="/contact">Contact</router-link>
        </nav>
        <router-view/>
    </div>
</template>

<script>
export default {
name: 'TabComponent'
}
</script>

<style scoped>
.tab_box{
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    width: 70%;
    margin: 0 auto;
}

.tab_content{
    padding: 0 30px;
    font-weight: bold;
    color: #2c3e50;
    text-decoration:none;
    position: relative;
    font-size: 1.45em;
}


@media screen and (max-width:480px) {
    .tab_content{
        padding: 5px 10px;
        font-size: 1em;
    }
}

.tab_content::after {
position: absolute;
left: 0;
content: '';
width: 100%;
height: 1.5px;
background: #2c3e50;
bottom: -1px;
transform: scale(0, 1);
transform-origin: right top; /*変形（アンダーラインの伸長）の原点がaタグ（各メニュー）の右端*/
transition: transform 0.7s;  /*変形の時間*/
}

.tab_content:hover::after {
transform: scale(1, 1); /*ホバー後、x軸方向に1（相対値）伸長*/
}

.tab_content.router-link-exact-active {
  color: #943db4;
}

</style>