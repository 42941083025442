import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/HomeView.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView,
  },
  {
    path: '/company',
    name: 'company',
    component: () => import('../views/CompanyView.vue')
  },
  {
    path: '/service',
    name: 'service',
    component: () => import('../views/ServiceView.vue')
  },
  {
    path: '/recruit',
    name: 'recruit',
    component: () => import('../views/RecruitView.vue')
  },
  {
    path: '/contact',
    name: 'contact',
    component: () => import('../views/ContactView.vue')
  }
]

const router = new VueRouter({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes
})

export default router
