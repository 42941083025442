<template>
  <div class="main anim-box fadein is-animated">
      <img src='@/app1/assets/images/logo.png' alt='会社ロゴ'>
    <TabComponent/>
  </div>
</template>

<script>
import TabComponent from '@/app1/components/TabComponent.vue'

export default {
  name: 'HomeView',
  components: {
    TabComponent,
  }
}
</script>

<style scoped>
  img{
    display: block;
    width: 550px;
    height: auto;
    margin: 0 auto;
    filter: drop-shadow(5px 5px 2px rgba(0, 0, 0, 0.1));
  }

  @media screen and (max-width:480px) {
    img{
      width: 100%;
    }
  }
</style>
